/* variables */
/* main colors */
$blue: #005792;
$cyan: #2196F3;
$black: #000000;
$orange: #FF6449;
$red: #D32F2F;
$yellow: #FFDE59;
$green: #007d6a;
/* bright colors */
$blue_bright: #a3cef3;
$cyan_bright: #b6dae0;
$green_bright: #b2d9d2;
$orange_bright: #e78b71;
$red_bright: #f14773;
$yellow_bright: #e5d28a;

/*  light 1 colors*/
$blue_light_1: #d0e6f9;
$cyan_light_1: #daecef;
$green_light_1: #d4eae6;
$orange_light_1: #f1bbaa;
$red_light_1: #f697af;
$yellow_light_1: #eee2b4;

/* light 2 colors */
$blue_light_2: #e7f2fc;
$cyan_light_2: #ecf5f7;
$green_light_2: #e5f2f0;
$orange_light_2: #f8ded6;
$red_light_2: #fbd5df;
$yellow_light_2: #f8f2de;

/* dark colors */
$blue_dark_1: #0f76d0;
$cyan_dark_1: #347c93;
$green_dark_1: #006454;
$orange_dark_1: #c1431f;
$red_dark_1: #c30f3d;
$yellow_dark_1: #c9a72b;

/* override bulma colors */
$success: $black;
$info: $cyan;
$warning: $orange;
$danger: $red;
$primary: $green_dark_1;
$link: $blue;

/* Text Color */
/* Form Elements */
$main_form_text_color: #343434;
$secondary_form_text_active: #8c8c8c;
$secondary_form_text_disable: #989898;
/* UI Text */
$main_ui_text_color: #000000;
$secondary_ui_text_active: #8c8c8c;
$secondary_ui_text_disable: #989898;
/* Background Color */
$light_background_color: #fbfbfb;
$light_gray: #E5E5E5;


$tabs-link-hover-color: white;
$tabs-link-active-color: white;
$tabs-link-hover-border-bottom-color: $black;
$tabs-boxed-link-hover-background-color: $black;
$tabs-boxed-link-active-background-color: $black;
$tabs-boxed-link-active-border-color: $black;
$tabs-toggle-link-border-color: $black;
$tabs-toggle-link-hover-background-color: $black;
$tabs-toggle-link-hover-border-color: $black;
$tabs-toggle-link-active-background-color: $black;
$tabs-toggle-link-active-border-color: $black;