.suggestionsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 1rem;
}

.chip {
    animation: fadeIn 0.3s ease-in-out forwards;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.2s ease-in-out;
}

.chip:hover:not(.disabled) {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.chip {
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 16px;
    background-color: var(--dark, #363636);
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
}

.chipIcon {
    margin-right: 6px;
    width: 20px;
    height: 20px;
} 