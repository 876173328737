.Custom__Group__Header {
  border-top: 16px solid #e3e3e3;
  border-left: 16px solid #e3e3e3;
  background: #EDEDED !important;
  border-radius: 30px !important;
  outline: 1px solid #ccc;
  outline-offset: -8px ;
}
.Custom__Group__Header td {
  color:  #999;
  font-size: 11px;
}
.Custom__Group__Header + .VueTables__row  {
  border-top: 12px solid #fff;
}
.VueTables__row  {
  border-left: 16px solid #e3e3e3; 
}
.rowNumber {
  width: 1px;
  min-width: 6px;
  max-width: 6px;
}
.rowColorContainer {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  min-width: 6px;
  width: 6px;
  max-width: 6px !important;
  padding: 0px !important;
}
.rowColor {
  height: 30px;
  min-width: 6px;
  width: 6px;
  max-width: 6px;
}
.VueTables__limit, .VueTables__limit-field{
  position: absolute; 
  right: 16px !important; 
  top: 3px; 
  max-width: 250px;
  font-size: 12px;
  z-index: 10000
}
.VuePagination__count  {
  position: absolute; 
  left: 16px !important; 
  top: 8px; 
  max-width: 250px;
  font-size: 12px;
}
.VueTables__table > thead,
.VueTables__table > thead > tr, 
.VueTables__table > thead > tr > th {
  background: #eee !important;
  color: #888 !important;
  font-size: 11px;
  font-weight: 500;
}
.v-popper__popper {
  box-sizing: border-box;
  letter-spacing: normal;
  color: #3d3d3d;
  background-color: #fff;
  max-width: 300px;
  padding: 8px 8px 5px 8px;
  border-radius: 4px;
  border: solid 1px black;
  box-shadow: 0 7px 8px 0 rgba(170, 170, 170, 0.2), 0 5px 22px 4px rgba(170, 170, 170, 0.14),
    0 12px 17px 2px rgba(170, 170, 170, 0.12);
}

.no-scroll {
  overflow-y: hidden !important;
}
.ag-tool-panel-wrapper > * {
  width: 100%;
}
.ag-tool-panel-wrapper {
  position: absolute;
  left: -300px;
  transition: left 0.3s ease;
  height: 100%;
  background-color: $green_bright !important;
  text-align: center;
  min-width: 300px;
  width: 300px;
  max-width: 300px;
}
.ag-side-bar:hover .ag-tool-panel-wrapper {
  left: 0;
}
.actions-button-cell {
  overflow: visible;
}
.ag-row {
  z-index: 0;
}
.ag-row.ag-row-hover {
  z-index: 1;
}
.orderTable_no_selected_order {
  display: grid;
  grid-template-columns: 100% 0%;
  grid-template-rows: 1fr;
  height: 100%;
}
.orderTable_selected_order {
  display: grid;
  grid-template-columns: 13% 87%;
  grid-template-rows: 1fr;
  height: 100%;
}
.ag-details-row {
  background-color: $yellow_light_2 !important;
}
.ag-cell { text-align: left;} 

/* Make sidebar background transparent */
.ag-theme-balham .ag-side-bar,
.ag-theme-balham .ag-tool-panel-wrapper,
.ag-theme-balham .ag-tool-panel-container {
  background-color: transparent !important;
}

/* Make individual tool panels transparent */
.ag-theme-balham .ag-tool-panel {
  background-color: transparent !important;
}

/* Ensure the content within panels remains visible */
.ag-theme-balham .ag-side-bar .ag-tool-panel-wrapper .ag-react-container,
.ag-theme-balham .ag-side-bar .ag-tool-panel-wrapper .ag-filter-toolpanel-instance {
  background-color: rgba(255, 255, 255, 0.95) !important;
}

/* Adjust text color for better visibility */
.ag-theme-balham .ag-side-bar,
.ag-theme-balham .ag-tool-panel-wrapper {
  color: #000 !important;
}

/* Base styles for the sidebar */
.ag-theme-balham .ag-side-bar {
  position: relative;
  width: 20px;
  transition: width 0.3s ease;
  background: transparent;
  z-index: 1;
  right: 0;
  margin-right: -1px;

  &:hover {
    width: 322px;

    .ag-tool-panel-wrapper {
      transform: translateX(0);
      opacity: 1;
      pointer-events: all;
    }
  }
}

/* Tool Panel Wrapper */
.ag-theme-balham .ag-tool-panel-wrapper {
  position: absolute;
  top: 0;
  right: -1px;
  width: 300px;
  height: 100%;
  background: white;
  transition: all 0.3s ease;
  transform: translateX(100%);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 5;
  padding-right: 1px;
}

/* Side Buttons Container */
.ag-theme-balham .ag-side-buttons {
  width: 20px;
  position: relative;
  z-index: 15;
  background: #f8f8f8;
}

/* Individual Side Button */
.ag-theme-balham .ag-side-button {
  position: relative;
  width: 100%;
  padding: 8px 0;
  cursor: pointer;
  background: transparent;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e0e0e0;
  }
}

/* Show panel on hover or when selected */
.ag-theme-balham .ag-side-bar:hover {
  .ag-side-button:hover + .ag-tool-panel-wrapper,
  .ag-side-button.ag-selected + .ag-tool-panel-wrapper {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
    
    &.ag-hidden {
      display: block !important;
      visibility: visible !important;
      opacity: 1;
      pointer-events: all;
    }

    /* Show all nested content */
    .ag-tool-panel,
    .ag-filter-panel,
    .ag-column-panel,
    .ag-columns-tool-panel,
    .ag-filter-toolpanel,
    .ag-column-select,
    [ref^="eFilter"],
    [ref^="eColumn"],
    [class*="ag-"],
    .ag-filter-toolpanel-search,
    .ag-filter-list-panel,
    .ag-column-select-header,
    .ag-column-select-list,
    .ag-filter-list,
    .ag-filter-toolpanel-instance {
      display: block !important;
      visibility: visible !important;
      opacity: 1 !important;
      height: auto !important;
      min-height: 30px;
      width: 100% !important;
      background: white;
    }
  }
}

/* Keep panel visible while hovering over it */
.ag-theme-balham .ag-side-bar:hover {
  .ag-tool-panel-wrapper:hover {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;

    &.ag-hidden {
      display: block !important;
      visibility: visible !important;
      opacity: 1;
      pointer-events: all;
    }
  }
}

/* Selected Button State */
.ag-theme-balham .ag-side-button-selected {
  background-color: #e0e0e0;

  & + .ag-tool-panel-wrapper {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;

    &.ag-hidden {
      display: block !important;
      visibility: visible !important;
      opacity: 1;
      pointer-events: all;
    }
  }
}

/* Panel Content */
.ag-theme-balham .ag-tool-panel-wrapper {
  .ag-react-container {
    height: 100%;
    width: 100%;
  }
}


/* Example: when the nth child button is hovered,
   show the nth panel. This needs index alignment though. */
   .ag-side-bar:has(
    .ag-side-buttons > .ag-side-button:nth-child(1):hover
  ) > .ag-tool-panel-wrapper:nth-of-type(1) {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  }
  