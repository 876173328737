@import '../../theme/variables.scss';
@import '../../theme/color_variables.scss';

.sb_tooltip,
.sb_tooltip_100 {
    width: max-content;
    position: relative;
    display: inline-block;
    cursor: pointer;

    &.sb_tooltip_100 {
        width: 100%;
    }
}

.sb_tooltip,
.sb_tooltip_100 {

    .sb_tooltiptext {
        visibility: hidden;
        width: 200px;
        font-size: 16px;
        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
        color: $success;
        text-align: left;
        border-radius: 5px;
        padding: 5px 10px 5px 10px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
    }
}

.sb_tooltip:hover,
.sb_tooltip_100:hover {
    .sb_tooltiptext {
        animation-name: tooltipDisplay;
        animation-duration: 0.4s;
        animation-delay: 0.4s;
        animation-fill-mode: forwards;
    }
}

@keyframes tooltipDisplay {
    0% {
        opacity: 0;
    }

    100% {
        visibility: visible;
        opacity: 1;
    }
}



.sb_tooltip_header {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.sb_tooltip_header .sb_tooltiptext {
    visibility: hidden;
    width: 200px;
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    color: $success;
    text-align: left;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: 125%; /* Position below the element */
    left: 50%;
    transform: translateX(-50%); /* Center the tooltip */
    margin-left: 0;
    opacity: 0;
    transition: opacity 0.3s;
}

.sb_tooltip_header:hover .sb_tooltiptext {
    animation-name: tooltipDisplay;
    animation-duration: 0.4s;
    animation-delay: 0.4s;
    animation-fill-mode: forwards;
}