.multi-select-container {
  width: 100%;
  position: relative;
}
.dropdown {
  width: 100%;
}
.dropdown-trigger {
  width: 100%;
}
.dropdown-trigger button {
  width: 100%;
  justify-content: space-between;
  text-align: left;
}
.dropdown-menu {
  display: none;
  width: 100%;
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
.dropdown.is-active .dropdown-menu {
  display: block;
  z-index: 9999; 
}
.dropdown-content {
  width: 100%;
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
  position: relative;
}

/* Fixed header section */
.dropdown-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  border-bottom: 1px solid #dbdbdb;
  padding: 0.5rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

/* Style for the group headers */
.dropdown-item p.has-text-weight-bold {
  background-color: white;
  margin: 0;
  padding: 0.5rem;
  padding-top: 0rem;
  font-weight: bold;
}

.dropdown-header .search-input {
  margin-bottom: 0.5rem;
}

.dropdown-item {
  padding: 0.5rem;
  text-align: left;
  background-color: white; /* Ensure background is white */
  width: 100%; /* Make sure it spans full width */
}

.dropdown-content > .dropdown-item:first-child {
  margin-top: 0;
  padding-top: 0;
}

.options-container {
  width: 100%;
  overflow-y: auto;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  background-color: white; /* Ensure background is white */
}

.buttons-container button {
  flex: 1;
}

/* Add background color to the group labels */
.dropdown-item > p {
  background-color: white;
  margin: 0;
  padding: 0.5rem;
  width: 100%;
}

.checkbox-placeholder {
  color: #dbdbdb;
  margin-right: 0.5rem;
}
.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
  max-height: 60px;
  overflow-y: auto;
  padding: 0.5rem;
}
.input {
  width: 100%;
}
.icon-text {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
}
.icon-text .icon {
  margin-right: 0.5rem;
}

.search-input {
  border-radius: 4px;
}

/* Custom scrollbar styles */
.options-container::-webkit-scrollbar {
  width: 6px;
}
.options-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}
.options-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}
.options-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Ensure full width for all elements */
.dropdown-menu, .dropdown-content, .dropdown-item, .dropdown-header {
  width: 100%;
  
  box-sizing: border-box;
}

.select-all-container {
  border-top: 1px solid #dbdbdb;
  padding: 0.5rem;
  padding-bottom: 0rem;
  background-color: white;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  margin: 0;
}