@import '../../theme/color_variables.scss';

// .portal-table .ag-checkbox-input-wrapper {
//     visibility: hidden;
// }

// .portal-table .ag-row-hover .ag-checkbox-input-wrapper {
//     visibility: visible;
// }

// .portal-table .ag-checkbox-input-wrapper.ag-checked {
//     visibility: visible;
// }

// .portal-table .checkbox.checkbox-selected {
//     visibility: visible;
// }

// .portal-table .ag-header-select-all .ag-checkbox-input-wrapper {
//     visibility: visible;
// }

.ag-theme-alpine.portal-table .ag-header {
    background-color: $black;
}

.ag-theme-alpine.portal-table .ag-header-cell-label {
    color: white;
    font-weight: 500;
}