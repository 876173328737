@import '../theme/color_variables.scss';

.shyftbase_nav {
    display: flex;
    height: 100%;
    background-color: #ddd;

    &.dark_theme {
        background-color: #0F2A46;
    }
}

.shyftbase_main_nav {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.shyftbase_main_nav_items {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.shyftbase_main_nav_item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    font-size: 9px;
    cursor: pointer;
    color: #333;
    fill: #333;
    transition: background-color 0.3s, color 0.3s, fill 0.3s;

    .nav-icon-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .notification-badge {
        position: absolute;
        top: -4px;
        right: -4px;
        width: 8px;
        height: 8px;
        background-color: #ff0000;
        border-radius: 50%;
        border: 2px solid #fff;
    }

    .dark_theme & {
        color: #FFFFFF;
        fill: #FFFFFF;

        .notification-badge {
            border-color: #0F2A46;
        }
    }

    &.logo_light_theme {
        background-color: $success;
        margin-bottom: auto;
    }
    &.logo_dark_theme {
        background-color: #ffffff;
        margin-bottom: auto;
    }

    &.help {
        margin-top: auto;
    }

    &.empty {
        background-color: transparent !important;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: #000 !important;
        fill: #000 !important;

        .dark_theme & {
            background-color: rgba(255, 255, 255, 0.2);
            color: #FFFFFF !important;
            fill: #FFFFFF !important;
        }

        .Polaris-Icon svg {
            fill: #000 !important;

            .dark_theme & {
                fill: #FFFFFF !important;
            }
        }
    }

    &.selected {
        background-color: rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        border-left: 3px solid #333;
        color: #000 !important;
        fill: #000 !important;

        .dark_theme & {
            background-color: rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
            border-left: 3px solid #ffffff;
            color: #FFFFFF !important;
            fill: #FFFFFF !important;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);

            .dark_theme & {
                background-color: rgba(255, 255, 255, 0.3);
            }
        }

        .Polaris-Icon svg {
            fill: #000 !important;

            .dark_theme & {
                fill: #FFFFFF !important;
            }
        }
    }

    .Polaris-Icon {
        margin: 1px;
        height: 20px !important;

        svg {
            fill: currentColor;
        }
    }

    .sb_tooltiptext_nav {
        visibility: hidden;
        min-width: 150px;
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(7px);
        color: #fff;
        text-align: left;
        border-radius: 5px;
        padding: 5px 10px;
        position: absolute;
        font-size: 16px;
        z-index: 1;
        top: 25%;
        left: 50px;
        margin-left: 0;
        opacity: 0;
        transition: opacity 2s;

        .dark_theme & {
            background-color: rgba(255, 255, 255, 0.8);
            color: $success;
        }
    }

    &:hover .sb_tooltiptext_nav {
        animation-name: tooltipDisplay;
        animation-duration: 0.4s;
        animation-delay: 0.4s;
        animation-fill-mode: forwards;
    }
}

@keyframes tooltipDisplay {
    0% {
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}