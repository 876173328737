@keyframes characterRipple {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.titleContainer {
  display: inline-block;
  position: relative;
}

.animatedChar {
  display: inline-block;
  animation: characterRipple 0.12s cubic-bezier(0,.73,.97,.62) 1;
  animation-play-state: running;
}

.titleGlow {
  display: none;
}

.suggestionText {
  text-align: center;
  margin: 1rem 0;
  min-height: 24px;
  font-size: 1rem;
  color: #666;
  opacity: 0;
  animation: fadeInOut 4s ease-in-out infinite;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  20%, 80% { opacity: 1; }
} 